import { Modify } from "@biblioteksentralen/types";
import { filters } from "@libry-content/common";
import {
  groqEventStart,
  ResolvedRepeatedEventOccurence,
  resolveRepeatedEvents,
} from "@libry-content/common/src/events/groq";
import { LocalizedField } from "@libry-content/localization";
import { Event, Library } from "@libry-content/types";
import { groq } from "next-sanity";

export const resolveEventGroqProjection = groq`
  ...,
  'eventStart': ${groqEventStart},
  'eventType': eventType->label,
  'library': library->{name, _id, slug, _type}
`;

export type ResolvedEvent = Modify<
  Event | ResolvedRepeatedEventOccurence,
  {
    eventType?: LocalizedField<string>;
    library?: Pick<Library, "name" | "_id" | "slug" | "_type">;
  }
>;

export const resolveEventSummaryGroqProjection = groq`
  {${resolveEventGroqProjection}}
  {
    _id,
    _type,
    eventStart,
    startDate,
    title,
    eventStatus, 
    teaser,
    allDay,
    eventEnd,
    endDate,
    promotion,
    image,
    externalLocation,
    location,
    library,
    eventType,
    targetAudiences,
    series { id }
  }
`;

export type ResolvedEventSummary = Pick<
  ResolvedEvent,
  | "_id"
  | "_type"
  | "eventStart"
  | "startDate"
  | "title"
  | "eventStatus"
  | "teaser"
  | "allDay"
  | "eventEnd"
  | "endDate"
  | "promotion"
  | "image"
  | "externalLocation"
  | "location"
  | "library"
  | "eventType"
  | "targetAudiences"
> & { series?: { id: string } }; // series is only defined if _type === "resolvedRepeatedEventOccurence", but couldnt find a way to type this

export const unFinishedEventsGroqFilter = groq`
  *[_type == 'event' && ${filters.eventNotFinished}]
    ${resolveRepeatedEvents(filters.repeatedEventNotFinished)}
    | order(startDate asc, ${groqEventStart} asc)
`;

export const finishedEventsGroqFilter = groq`
  *[_type == 'event' && ${filters.eventFinished}]
    ${resolveRepeatedEvents(filters.repeatedEventFinished)}
    | order(startDate desc, ${groqEventStart} desc)
`;

// #eventIdOrKey since we need to handle repeated events. A repeated event occurence is identified by its _key in the repeatedDates array
export const singleEventQuery = groq`*[ 
    _type == "event" && 
    (
      _id == $eventIdOrKey || 
      $eventIdOrKey in repeatedDates[]._key
    )
  ]
  ${resolveRepeatedEvents(groq`
      $eventIdOrKey == ^._id && (${filters.repeatedEventNotFinished}) || // If we query by the id of an event with repeated dates (and not a specific repeated date by querying the _key), we want to return the first upcomming repeated date. This is forexample used in preview
      _key == $eventIdOrKey
  `)}
  | order(startDate asc, ${groqEventStart} asc)
  [0]
  { ${resolveEventGroqProjection} }
`;
