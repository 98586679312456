import { LocalizedField, localizedPortableTextGroq } from "@libry-content/localization";
import { StaticPage } from "@libry-content/types";
import { groq } from "next-sanity";

export const resolveStaticPageGroqProjecton = groq`
  ...,
  "bodyText": {
    ${localizedPortableTextGroq("body")}
  },
`;

export type ResolvedStaticPage = StaticPage & {
  bodyText?: LocalizedField<string>;
};

export const resolveStaticPageSummary = groq`
  _type,
  _id,
  title,
  image,
`;

export type ResolvedStaticPageSummary = Pick<StaticPage, "_id" | "title" | "_type" | "image">;
